<template>
  <img
    width="200"
    :src="appLogoImage"
    :alt="appName"
    :title="appName"
  >
</template>
<script>
import { $themeConfig } from '@themeConfig'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
</style>
